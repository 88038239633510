import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { IoMdAnalytics, IoIosPerson, IoIosBusiness } from "react-icons/io";
import { ReactElement } from "react";

import How from '../../assets/how.png';
import Apple from '../../assets/apple.png';
import Google from '../../assets/google.png';

interface FeatureProps {
  text: string;
  iconBg: string;
  numId: ReactElement;
  detail: string;
}

const Feature = ({ text, numId, iconBg, detail }: FeatureProps) => {
  return (
    <Box>
      <Stack direction={"row"} align={"center"}>
        <Flex
          w={8}
          h={8}
          align={"center"}
          justify={"center"}
          rounded={"full"}
          bg={iconBg}
        >
          {numId}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
      <Text color={"gray.500"} align={"left"} mt={2} fontSize={"lg"}>
        {detail}
      </Text>
    </Box>
  );
};

export default function HowitWorks() {
  return (
    <Box bg={useColorModeValue("red.50", "black")} mt={20} p={10}>
      <Container maxW={"7xl"} mt={20}>
      <Stack spacing={0} align={'center'}>
        <Heading>Download Our Apps</Heading>
        <Text mb={4}>Find an Artisan today or Start Making More Money with our App</Text>
        <Grid templateColumns={{ sm: '1fr 1fr' }} style={{alignItems: 'center'}} gap={4}>
        <a style={{marginLeft: 15}} href="https://play.google.com/store/apps/developer?id=The+Sapio+Works+LTD" target="blank_">
        <Image width="210px" src={Google} />
        </a>
        <a href="https://apps.apple.com/gb/developer/sapioworks-ltd/id1764118094" target="blank_">
        <Image width="240px" src={Apple} />
        </a>
        </Grid>
      </Stack>
        <Box m={{md:20}}>
          {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}> */}
          <Grid templateColumns={{ sm: '1fr', md: 'repeat(1, 1fr 2fr)' }} gap={4}>
            <Stack align={"flex-start"} spacing={4}>
              <Text
                textTransform={"uppercase"}
                color={useColorModeValue("black", "yellow.400")}
                fontWeight={600}
                fontSize={"sm"}
                bg={useColorModeValue("yellow.100", "yellow.900")}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
              >
                How it works
              </Text>
              <Heading textAlign={'left'}>Find an Artisan in 3 Easy Steps</Heading>
              <Stack
                spacing={4}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue("gray.100", "gray.700")}
                  />
                }
              >
                <Feature
                  numId={<Text fontWeight={600}>1</Text>}
                  iconBg={useColorModeValue("yellow.100", "yellow.900")}
                  text={"Post Job"}
                  detail={"Tell us about the task you need to do"}
                />
                <Feature
                  numId={<Text fontWeight={600}>2</Text>}
                  iconBg={useColorModeValue("green.100", "green.900")}
                  text={"Choose Artisan"}
                  detail={"Choose from our list of SapioArtisans"}
                />
                <Feature
                  numId={<Text fontWeight={600}>3</Text>}
                  iconBg={useColorModeValue("purple.100", "purple.900")}
                  text={"Schedule Job"}
                  detail={"Send a contract and viola!"}
                />
              </Stack>
            </Stack>
            <Flex>
              <Image
                rounded={"md"}
                alt={"feature image"}
                src={How}
                objectFit={"cover"}
              />
            </Flex>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
